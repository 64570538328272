/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";


ion-content, ion-list {
  --background: #f8f6f3;
  background: #f8f6f3 !important;
  ion-menu & {
    --background: #fff;
    background: #fff !important;
  }
}

strong, .text-dark {
  color: var(--ion-color-dark) !important;
  --color: var(--ion-color-dark) !important;
  font-weight: 600;
}

small, .small {
  font-size: 85%;
}

ion-header ion-title {
  color: var(--ion-color-dark);
}

.segment-button {
  color: var(--ion-text-color);
}

ion-segment-button {
  --border-color: rgb(212, 212, 212);
  ion-label {
    padding: 10px 0;
  }
}

.segment-button-checked {
  color: var(--ion-color-dark);
}

ion-content ion-button:not(.button-small) {
  min-width: 200px;
  height: 46px;
  box-shadow: none;
  --box-shadow:none;
  border: 0;
  text-transform: capitalize;
}

ion-toggle {
  --background: rgb(143, 143, 143);
  --background-checked: #17a082;

  --handle-background: #ffffff;
  --handle-background-checked: rgb(255, 255, 255);
}

:root {
  --detail-icon-color: red;
}

.left-border-orange {
  border-left: 3px solid orange;
}
.height-fixed {
  height: 90px;
  background: white;
  display: flex;
}
.visits-container {
  ion-label {
    padding: 10px 0;
  }
}
.icon-circle {
  text-align: center;
  display: inline-block;
  font-size: 1.2em;
  margin: 7px auto;
  font-weight: 500;
  color: white;
  vertical-align: middle;
  width: 100%;
}
ion-header ion-avatar {
  margin: 0 auto;
  height: 45px;
  width: 45px;
}
.bg-orange {
  background: var(--ion-color-secondary) !important;
}

.orange {
  color: var(--ion-color-secondary) !important;
}

.orange-dark {
  color: var(--ion-color-primary) !important;
  font-weight: 600;
}

.text-gray {
  color: var(--ion-color-medium);
}

.transparent, .transparent ion-item {
  background: transparent !important;
  background-color: transparent !important;
  --background: transparent !important;
  --background-color: transparent !important;
}
.big-data {
  font-size: 3em;
  font-weight: bold;
  small {
    font-size: 60%;
  }
}

@media (max-width: 600px) {
  .big-data {
    font-size: 2em;
  }
  .white-card {
    margin: 0;
    padding: 5px;
  }
}


.fade-in {
  animation: fadeIn ease 1.5s;
  -webkit-animation: fadeIn ease 1.5s;
  -moz-animation: fadeIn ease 1.5s;
  -o-animation: fadeIn ease 1.5s;
  -ms-animation: fadeIn ease 1.5s;
  }
  @keyframes fadeIn {
  0% {opacity:0;}
  100% {opacity:1;}
  }
  
  @-moz-keyframes fadeIn {
  0% {opacity:0;}
  100% {opacity:1;}
}
  
  @-webkit-keyframes fadeIn {
  0% {opacity:0;}
  100% {opacity:1;}
}
  
  @-o-keyframes fadeIn {
  0% {opacity:0;}
  100% {opacity:1;}
}
  
  @-ms-keyframes fadeIn {
  0% {opacity:0;}
  100% {opacity:1;}
}

ion-fab-button {
  width: 40px;
  height: 40px;
  margin-right: 20px;
}

.pointer {
  cursor: pointer;
}

ion-menu .label-stacked.sc-ion-label-md-h {
  font-size: 1.2rem;
  color: var(--ion-color-dark);
  text-transform: uppercase;
  margin-bottom: 16px;
}

.stats-card {
  height: 135px;
}

.white-card {
  box-shadow: none;
  border-radius: 10px;
  border: 1px solid rgb(244, 244, 244);
  color: var(--ion-text-color);
  p {
    height: 25px;
  }
}

.inline-text-toggle {
  display: inline-block;
  vertical-align: middle;
  font-size: 80%;
  margin: 12px 0;
  ion-toggle {
    padding: 0 15px;
  }
}

.visit-page .checkbox-disabled,
.item-interactive-disabled * {
  opacity: 1 !important;
}

.item-interactive-disabled ion-input { 
  color: #555554;
}

.white-input ion-input {
  background: white;
  display: block;
  padding: 5px 10px !important;
  width: 100% !important;
  margin-top: 16px;
  text-align: right;
  border-radius: 5px;
}

.white-input ion-label.label-stacked {
  font-size: 18px !important;
  margin-left: 10px;
}

.ion-margin-horizontal {
  --margin-start: var(--ion-margin,16px) !important;
  --margin-end: var(--ion-margin,16px) !important;
  margin-left: var(--ion-margin,16px) !important;
  margin-right: var(--ion-margin,16px) !important;
}

.ion-padding-horizontal {
  --padding-start: var(--ion-padding,16px) !important;
  --padding-end: var(--ion-padding,16px) !important;
  padding-left: var(--ion-padding,16px) !important;
  padding-right: var(--ion-padding,16px) !important;
}

.ion-margin {
  --margin-start: var(--ion-margin,16px) !important;
  --margin-end: var(--ion-margin,16px) !important;
  margin-left: var(--ion-margin,16px) !important;
  margin-right: var(--ion-margin,16px) !important;
}
.ion-padding {
  padding-inline-end: var(--ion-padding,16px) !important;
  padding-inline-start: var(--ion-padding,16px) !important;
  padding-left: var(--ion-padding,16px) !important;
  padding-right: var(--ion-padding,16px) !important;
}

.ion-margin-start {
  --margin-start: var(--ion-margin,16px) !important;
  margin-left: var(--ion-margin,16px) !important;
}

.ion-margin-end {
  --margin-end: var(--ion-margin,16px) !important;
  margin-right: var(--ion-margin,16px) !important;
}

app-date-range-calendar {
  height: 90%;
  top: 5% !important;
}

.alert-input {
  font-size: 1.8em !important;
}
